.button {
    padding: 0.5rem 1rem;
    background-color: #d7fc51;
    border: 0;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #1D1E29;
    cursor: pointer;
    transition: opacity .3s ease;
}

.playLearn {
    margin-top: 90px;
    margin-bottom: 75px;
}

.container {
    max-width: 1280px;
    padding: 0 4rem;
    margin: 0 auto;
}

.intro {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
}

.introContainer {
    width: 66%;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.625rem;
    line-height: 3.25rem;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0.5rem;
}

.subtitle {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #ffffff;
}

.playEarn {
    margin-top: 100px;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
}

.playEarnMobile {
    display: none;
}

.person {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.personImg {
    height: 1px;
    width: 1px;
    position: relative;
}

.image {
    position: absolute;
    z-index: -1;
    bottom: -100px;
    left: 50%;
    transform: translateX(-47%);
    width: 489px;
    height: 573px;
}

.personImg span span img {
    position: absolute;
    top: 0;
    left: 0;
    width: 1000px;
    height: 100px;
}

.playTitleBig {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: rgba(215, 252, 81, 1);
    margin-bottom: 71px;
}

.block {
    display: flex;
    align-items: center;
}

.blockTitle {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: #FFFFFF;
}

.blockText {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #ffffff;
}

@media (min-width: 2000px) {
    .container {
        max-width: 80%;
    }

    .introContainer {
        width: 100%;
    }

    .personImg {
        height: 600px;
        width: 200px;
        position: relative;
    }

    .image {
        position: absolute;
        z-index: -1;
        bottom: 40px;
        left: 50%;
        transform: translateX(-47%);
        width: 600px;
        height: 573px;
    }

  }

  @media (min-width: 1440px) and (max-width: 1920px) {
    .container {
        max-width: 90%;
    }
  }

@media only screen and (max-width: 1024px) {
    /* .play {
        padding-bottom: 90px;
    } */

    .playLearn {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .container {
        padding: 0 1rem;
    }

    .introContainer {
        width: 100%;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 8px;
    }

    .playEarn {
        display: none;
    }

    .playTitleBig {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 0;
    }

    .blockTitle {
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .playEarnMobile {
        padding-top: 42px;
        display: block;
    }

    .choose {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0.75rem;
        margin-top: -0.25rem;
        margin-bottom: 52px;
    }

    .select {
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
        background-color: rgba(29, 30, 41, 1);
    }

    .chosen {
        text-decoration: underline;
    }

    .block {
        justify-content: center;
    }
}
